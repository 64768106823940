import React, { useState, useEffect } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Form } from "react-bootstrap";
import ClipLoader from "react-spinners/BounceLoader";
import { Col, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const paymentIntentApiUrl = process.env.REACT_APP_INTENT_API_URL;

const CheckoutForm = ({ paymentIntent: beIntent, setShowOverlay }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState("");
  const [status, setStatus] = useState(beIntent?.status || "");
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const { client_secret } = beIntent || {};

  useEffect(() => {
    const piStatus = beIntent?.status;
    if (piStatus === "canceled" || piStatus === "succeeded") {
      setStatus(piStatus);
      setShowPaymentForm(false);
    }
  }, [beIntent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowOverlay(true);
    if (!stripe || !elements) {
      toast.error("Stripe.js has not yet loaded.");
      return setShowOverlay(false);
    }

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: { name },
        },
      });

    if (stripeError) {
      toast.error(stripeError.message);
      return setShowOverlay(false);
    }

    setShowOverlay(false);
    setShowPaymentForm(false);
    setStatus(paymentIntent.status);
  };

  return showPaymentForm ? (
    <form id="payment-form" onSubmit={handleSubmit}>
      <label htmlFor="card" className="mb-3">
        Payment Info
      </label>
      <div className="d-flex justify-content-between mb-3">
        <label className="font-weight-bold">Amount</label>
        <label>
          {beIntent?.amount && beIntent?.currency
            ? `${beIntent.amount / 100} ${beIntent.currency}`
            : ""}
        </label>
      </div>
      <Form.Control
        required
        type="text"
        placeholder="Full Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className="mt-2 px-3 py-2 border rounded">
        <CardElement id="card" />
      </div>
      <div className="text-center">
        <Button type="submit" className="px-5 mt-4">
          Pay
        </Button>
      </div>
    </form>
  ) : (
    <h5 className="text-center">Payment {status}</h5>
  );
};

const App = () => {
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [showOverlay, setShowOverlay] = useState(true);
  const [error, setError] = useState(false);
  const queryId = new URLSearchParams(window.location.search)?.get("id");

  useEffect(() => {
    (async () => {
      try {
        const data = await axios.post(
          paymentIntentApiUrl,
          { id: queryId },
          { headers: { "Content-Type": "application/json" } }
        );
        setPaymentIntent(data?.data || null);
      } catch (error) {
        setError(JSON.stringify(error));
      }
      setShowOverlay(false);
    })();
  }, [queryId]);

  useEffect(() => {
    if (error) {
      toast.error("Unfortunately an error occurred.");
      setError(false);
    }
  }, [error]);

  const renderComponent = () => {
    if (window.location.pathname === "/payment" && queryId.startsWith("pi_"))
      return (
        <main className="d-flex justify-content-center mt-5">
          <Col lg={4} md={8} sm={12}>
            <h3 className="text-center">Payment</h3>
            {queryId && (
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  paymentIntent={paymentIntent}
                  setShowOverlay={setShowOverlay}
                />
              </Elements>
            )}
          </Col>
        </main>
      );

    return (
      <div className="text-center mt-5">
        <h5>404! Page not found</h5>
        <p>The page you're trying to find doesn't exist.</p>
      </div>
    );
  };

  return (
    <div className="orenda-frontend">
      {showOverlay && (
        <div className="d-flex flex-column justify-content-center align-items-center v-100 v-100 mt-5">
          <p>Please wait...</p>
          <ClipLoader size={30} />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={showOverlay ? "d-none" : null}>{renderComponent()}</div>
    </div>
  );
};

export default App;
